import Vue from 'vue'
import App from './App.vue'
import router from './Routes'
import store from './store/index'
import vuetify from './plugins/vuetify'
import * as VueGoogleMaps from 'vue2-google-maps';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
// import axios from 'axios'
// import qs from 'qs'
import  http from './common/http';
import {formatDate} from './common/tool'
import VFileUpload from "./components/FileUpload";
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'


import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
Vue.use(mavonEditor)
Vue.use(Toast,{
  position: "top-center",
  timeout: 3000,
  hideProgressBar: true,
  transition: "Vue-Toastification__fade",
});
Vue.use(ElementUI)
/**
 * @公共方法挂载
 */
Vue.prototype.$http = http
Vue.prototype.formatDate = formatDate;
Vue.component('v-file-upload', VFileUpload);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyB7OXmzfQYua_1LEhRdqsoYzyJOPh9hGLg',
  },
});

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  render: h => h(App), store
}).$mount('#app')
