<template>
  <v-container fluid>
    <div class="tables-basic">
      <v-row no-gutters class="d-flex justify-space-between mt-10 mb-6">
        <h1 class="page-title ">所有应用</h1>
        <v-btn class="primary offset-y" :to="{name:'createApp'}">创建应用</v-btn>
      </v-row>
      <v-row>        
        <v-col cols=12>
          <v-card class="mb-1">
            <!--
            <v-card-title class="pa-5 pb-3">
              <p>App List</p>
              <v-spacer></v-spacer>
              
              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on">
                    <v-icon color="textColor">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, i) in mock.menu"
                    :key="i"
                    @click="() => {}">
                    <v-list-item-title>{{ item }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-card-title>
            -->
            <v-simple-table  >
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="text-left pa-6">应用</th>
                    <th class="text-left">安装用户数</th>
                    <th class="text-left">应用状态</th>                    
                    <th class="text-left">最后更新日期</th>                    
                    <th class="text-left"></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="item in appsList" :key="item.name" >
                    <td class="pa-6">{{ item.zhName }}
                      <div class="card-light-grey">{{ item.appid }}</div>
                      
                    </td>
                    <td>{{ item.installedCount }}</td>
                    <td>{{ stateObj[item.state+''] }}</td>                    
                    <td>{{ formatDate(item.createTimestamp)}}</td>
                    <td>
                      <!-- <router-link :to="{ path: '/app/'+item.id+'/app-dashboard', params: {id: item.id} }" tag="span">                       -->
                        <v-btn           
                          class="text-capitalize ml-1 font-weight-regular"
                          color="primary"
                          @click="viewApp(item)"
                          text>查看应用
                          <v-icon class="mr-3">mdi-arrow-right</v-icon>
                        </v-btn>
                      <!-- </router-link> -->
                    </td>
                   
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import mock from './mock'

export default {
  name: 'Apps',
  data() {
    return {
      mock,
      appsList:[],
      stateObj:{
        '0':'正式版',
        '1':'测试版',
        '-1':'草稿',
        '9':'禁用'
      }
    }
  },
  mounted(){
     this.getAppsList();
     
  },
  methods:{
    viewApp(item){
    
      this.$router.push({
        name:'AppDashboard',
        params:{
          id: item.id,          
        }
      })
    },
    getAppsList(){
    
            this.$http.get('/api/apps').then(res=> {
                if(res.code==0){
                   this.appsList=res.data;
                }
                else{
                  this.$toast.error('请求失败');
                   
                }
            }).catch(function (error) {console.log(error);});
      
    }
  }
}

</script>

<style src="./Basic.scss" lang="scss"></style>
