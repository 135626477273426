<template>
  <v-container fluid>
    <div class="tables-basic">
      <!-- <div class="mt-10">
        <h1 class="page-title"> {{Detail.versionCode}}({{Detail.uversion}})</h1>
        <div>更新时间：{{formatDate(Detail.publishDateTime)}}</div>
      </div> -->
       <v-card style="margin-top:20px">
         <v-card-title>
          <v-icon class="mr-3" 
            color="text-grey"
           
            @click="toVersionList" 
            >mdi-arrow-left</v-icon>
            <span>{{parentTitle}}</span>
          </v-card-title>
          <h1 class="page-title" style="font-size: 2.2rem;">{{Detail.uversion}}</h1>
          
          <v-card-text>
            <p>{{stateObj[Detail.state]}}</p>
            <p>更新时间：{{formatDate(Detail.publishDateTime)}}</p>
          </v-card-text>
       </v-card>
     
      <v-divider></v-divider>
      <v-row>
        <v-col cols="12">
          <v-card class="pa-4">
            <v-row>
              <v-col cols="12">
                <h3>APK</h3>
                <v-simple-table  >
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th class="text-left pa-6">文件类型</th>
                        <th class="text-left">版本</th>
                        
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td class="pa-6">APK
                        
                        </td>
                        <td>{{Detail.uversion}}</td>
                       
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
              </v-col>
            </v-row>

             
              <v-row>
                <v-col cols="12">
                    <div class="d-flex justify-space-between align-center">
                        <h3>版本说明</h3>
                        <v-btn
                            text
                            color="primary"
                            @click="detailWindowVisible=true"
                          >
                          修改版本详情
                          </v-btn>
                     
                    </div>
                  
                      <mavon-editor  
                     class="previewEditor"
                      v-model="versionObj.log"
                      :subfield="markdown.subfield"  
                      :defaultOpen="markdown.defaultOpen" :toolbarsFlag="markdown.toolbarsFlag" :editable="markdown.editable"
                      />
                     
                </v-col>
               
              </v-row>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <el-dialog
      title="修改版本详情"
      :visible.sync="detailWindowVisible"
      width="1000px"
      class="detailWindow"
      :close-on-click-modal="false"
      >
      <v-card>
       
        <v-card-text style="margin-top:20px;height:560px;overflow: auto;">
          <v-container>
            <v-row>
              <v-col cols="2">
                <div class="mt-3 body-2" for="verName">版本名称 *</div>
              </v-col>
              <v-col lg="5" sm="10">
                <v-text-field
                  id="verName"
                  outlined
                  single-line
                  full-width
                  label=""
                  :maxlength="50"
                  counter="50"
                  v-model="versionObj.uVersionName"
                ></v-text-field>
                <p class="caption">
                  此名称旨在让您可以识别这个版本，不会向最终用户显示。我们根据该版本的第一个 app bundle 或 APK
                  提供了建议的名称，不过您可以修改此名称。
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2">
                <div class="mt-3 body-2" for="verName">版本说明</div>
              </v-col>
              <v-col cols="10">
              
                <mavon-editor v-model="versionObj.log" class="updateMavonEditor"/>
                <p class="caption">
                  已提供2种语言的版本说明
                </p>
                <p class="caption">
                  向用户介绍此版本的功能和特性。在相应的语言标签内输入每种语言的版本说明。
                </p>
              </v-col>
            </v-row>
           
          </v-container>
        </v-card-text>
       
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="darken-1"
            text
            @click="detailWindowVisible = false"
          >
            取消
          </v-btn>
          <v-btn
            color="primary darken-1"
            text
            @click="saveVersionDetail"
          >
            保存
          </v-btn>
        </v-card-actions>
      </v-card>
    </el-dialog>
  </v-container>
</template>
<style>
</style>
<script>
export default {
 
  data() {
    return {
      stateObj:{
        '0':'已发布',
        '-1':'草稿',
        '-2':'暂停'
      },
      detailWindowVisible:false,
      versionLogObj:{
        'en-us':'请在此处输入或粘贴zh-CN版的版本说明',
        'zh-cn':'请在此处输入或粘贴zh-CN版的版本说明'
      },
      titleObj:{
        0:'正式版',       
        1:'内部测试',
        2:'开放性测试',
      },
      versionObj:{
        versionId:'',
        uVersionName:'',
        log:''
      },
      markdown:{
        subfield:false,
        defaultOpen: 'preview',//edit： 默认展示编辑区域 ， preview： 默认展示预览区域 
        editable: false,
        toolbarsFlag: false,
      },
      Detail:{}
    };
  },
  computed: {
    versionId(){return this.$route.params.prepareId},
    
    type(){return this.$route.params.type},
    parentTitle(){
     return this.titleObj[this.type]
    }
  },
  mounted(){
   
    this.getVersionDetail();
  },
  methods: {
    setVersionObj(){
      this.versionObj={
        versionId:this.versionId,
        uVersionName:this.Detail.uversion,
        log:''
    };
   
    this.Detail.versionLogObj['zh-cn']?this.versionLogObj['zh-cn']=this.Detail.versionLogObj['zh-cn']:'';
      this.Detail.versionLogObj['en-us']?this.versionLogObj['en-us']=this.Detail.versionLogObj['en-us']:'';
      
          this.versionObj.log=`<zh-CN>\n${this.versionLogObj['zh-cn']}\n</zh-CN>\n<en-US>\n${this.versionLogObj['en-us']}\n</en-US>`;
    },
    getVersionDetail(){
      this.$http.get('/api/app/release/'+this.versionId).then(res=> {
                if(res.code==0){
                 this.Detail=res.data;
                 this.setVersionObj();
                }
                else{
                  this.$toast.error(res.message);
                   
                }
            }).catch(function (error) {console.log(error);});
    },
    saveVersionDetail(){
      this.$http.post('/api/app/release/save',this.versionObj).then(res=> {
                if(res.code==0){
                 
                  this.$toast.success(`修改版本详情成功成功`);
                  this.detailWindowVisible=false;
                  this.getVersionDetail();
                }
                else{
                  this.$toast.error(res.message);
                   
                }
            }).catch(function (error) {console.log(error);});
     
    },
   
    toVersionList(){
        switch(this.type){
            case 0:this.$router.push({name:'Tracks'});break;            
            case 1:this.$router.push({name:'internalTesting'});break;
            case 2:this.$router.push({name:'openTesting'});break;
        }
    },
    
  },
};
</script>

<style src="./index.scss" lang="scss"></style>
