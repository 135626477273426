import Vue from 'vue';
import Router from 'vue-router';

import Layout from '@/components/Layout/Layout';
import AppLayout from '@/components/Layout/AppLayout';

// Pages
import Dashboard from '@/pages/Dashboard/Dashboard';
import Typography from '@/pages/Typography/Typography'
import Tables from '@/pages/Tables/Basic'
import Notifications from '@/pages/Notifications/Notifications'
import Icons from '@/pages/Icons/Icons'
import Charts from '@/pages/Charts/Charts'
import Maps from '@/pages/Maps/Google'
import ErrorPage from "@/pages/Error/Error";
import Login from "@/pages/Login/Login";
import Apps from "@/pages/Apps/Apps";
import Apps_app from "@/pages/Apps/app";

import CreateNewApp from '@/pages/Apps/CreateNewApp';
import AppStore_app from '@/pages/AppStore/app';
import AppStore from '@/pages/AppStore/MainPage/index';
import AppLog from '@/pages/AppStore/Log/index';
// Pages for App
import AppDashboard from '@/pages/App/Dashboard/Dashboard';
import Production from '@/pages/App/Production/Production';
import ReleasePrepare from '@/pages/App/Releases/Prepare';
import VersionDetail from '@/pages/App/VersionDetail/index';
import trackApp from '@/pages/App/app';
import testingApp from '@/pages/App/Testing/app';
import internalTesting from '@/pages/App/Testing/internalTesting';
import openTesting from '@/pages/App/Testing/openTesting';
import openTestingRelease from '@/pages/App/Testing/openTestingRelease';
import internalTestingRelease from '@/pages/App/Testing/internalTestingRelease';

Vue.use(Router);

export default new Router({
  //base:'/console/',
  // mode:'history',
  routes: [    
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/app/:id/',      
      name: 'App',
      component: AppLayout,
      children: [
        {
          path: 'app-dashboard',
          name: 'AppDashboard',
          component: AppDashboard,
        },
        {
          path: 'edit-app',
          name:'editApp',
          component: CreateNewApp
        },
        {
          path:'tracks',       
          // component: {
          //     template: '<div><router-view></router-view></div>'
          //   },
          component: trackApp,
          children:[
            {
              path: '/',
              name:'Tracks',
              redirect: 'production'
            },
            {          
              path: 'production',
              name: 'Production',
              component: Production, 
            },
            {
              path: 'downloadLimit',
              name: 'DownloadLimit',
              component: ()=>import('@/pages/DownloadLimit/DownloadLimit'),
            },
            {          
              path: 'release/:prepareId/prepare',
              name: 'ReleasePrepare',
              component: ReleasePrepare, 
            },
            {          
              path: 'detail/:prepareId',
              name: 'productionVersionDetail',
              component: VersionDetail, 
            },
          ]
        },
        {
          path:'testing', 
          component: trackApp,
          children:[
            {
              path: '/',
              name:'Testing',
              redirect: 'open-testing'
            },
            {          
              path: 'open-testing',             
              component:testingApp,
              children:[
                {
                  path: '/',
                  name:'openTesting',
                  redirect: 'Version'
                },
                {          
                  path: 'version',
                  name: 'Version',
                  component: openTesting, 
                },
                {          
                  path: 'release/:prepareId/prepare',
                  name: 'openTestingRelease',
                  component: openTestingRelease, 
                },
                {          
                  path: 'detail/:prepareId',
                  name: 'openTestingVersionDetail',
                  component: VersionDetail, 
                },
              ]
              
            },
            {          
              path: 'internal-testing',             
              component:testingApp,
              children:[
                {
                  path: '/',
                  name:'internalTesting',
                  redirect: 'Version'
                },
                {          
                  path: 'version',
                  name: 'Version',
                  component: internalTesting, 
                },
                {          
                  path: 'release/:prepareId/prepare',
                  name: 'internalTestingRelease',
                  component: internalTestingRelease, 
                },
                {          
                  path: 'detail/:prepareId',
                  name: 'internalTestingVersionDetail',
                  component: VersionDetail, 
                },
              ]
              
            },
            
          ]
        }
       
       
      ]
    },
    {
    path: '/',
    redirect: 'login',
    name: 'Layout',
    component: Layout,
    children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard,
        },
        {
          path:'apps',       
          
          component: Apps_app,
          children:[
            {
              path: '/',
              name:'Apps',
              redirect: 'list'
            },
            {
              path: 'list',
              name: 'appsList',
              component: Apps
            },
          
            {
              path: 'create-new-app',
              name:'createApp',
              component: CreateNewApp
            },
          ]
        },
        
      
        {
          path: 'typography',
          name: 'Typography',
          component: Typography,
        },
        {
          path: 'tables',
          name: 'Tables',
          component: Tables
        },
        {
          path: 'notifications',
          name: 'Notifications',
          component: Notifications
        },
        {
          path: 'icons',
          name: 'Icons',
          component: Icons
        },
        {
          path: 'charts',
          name: 'Charts',
          component: Charts
        },
        {
          path: 'maps',
          name: 'Maps',
          component: Maps
        },
      ],
    },
    {
      path: '/store/apps',
     
      component: AppStore_app,
      children:[
        {
          path: '/',
      
          redirect: 'list'
        },
        {
          path: 'details',
          name: 'Maps',
          component: AppStore
        },
        {
          path: 'log',
          name: 'Log',
          component: AppLog
        },
      ],
      
    },
    {
      path: '*',
      name: 'Error',
      component: ErrorPage
    },
    {
      path: '/404',
      name: 'notFound',
      component: ErrorPage
    },
  ],
});
