<template>  
    <version title='开放性测试' :type="2"></version>
</template>

<script>
import version from "../../../components/version/index";
export default {
    data() {
        return {

        }
    },
    components: {  
        version
    },
}
</script>