<template>
  
  <prepare title='内部测试版本' :type="1"></prepare>
</template>
<style>
</style>
<script>
import prepare from "../../../components/prepare/index";
export default {
  
  components: {  
    prepare
  },
  data() {
    return {
      
    };
  },
  computed: {},
  methods: {
   
  },
};
</script>

