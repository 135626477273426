<template>
    <v-app class="pa-6">
        <Header @showregister="registerWindowVisible=true"/>
        <Sidebar v-bind:items="items"/>
        <v-main class="content">
            <router-view />
            <Footer />
        </v-main>
         <el-dialog
            title="注册"
            :visible.sync="registerWindowVisible"
            width="500px"   
            :close-on-click-modal="false" 
        >
        <v-card>      
          <v-card-text>                         
              <v-container>
                <v-row class="flex-column">
  
                  <v-form>
                    <v-col>
                      <v-text-field
                          v-model="createUsername"
                          label="用户名"
                          :rules="userNameRules"
                          required
                      ></v-text-field>
                      <v-text-field
                          v-model="createEmail"
                          :rules="emailRules"
                          label="邮箱"
                          required
                      ></v-text-field>
                      <v-text-field
                          v-model="createPassword"
                          :rules="passRules"
                          type="password"
                          label="密码"
                          hint="至少6个字符"
                          required
                      ></v-text-field>
                    </v-col>
                   
                    <v-col class="d-flex justify-center">
                      <v-btn
                          large
                          
                          :disabled="createUsername.length === 0 || createEmail.length === 0 || createPassword === 0"
                          color="primary"
                          @click="register"
                          width="200"
                      >
                        创建新用户</v-btn>
                    </v-col>
                  </v-form>
                
                </v-row>
              </v-container>
          
          </v-card-text>
        </v-card>
      </el-dialog>
    </v-app>
</template>

<script>
    import Header from '@/components/Header/Header';
    import Sidebar from '@/components/Sidebar/Sidebar';
    import Footer from "@/components/Footer/Footer";
    import './Layout.scss';

    export default {
        name: 'Layout',
        components: {Footer, Header, Sidebar },
        data(){
            return {
                items:[
                    { title: '所有应用', icon: 'mdi-application', link: 'Apps' }],
                    registerWindowVisible:false,
                    createUsername:'',
                    createEmail: '',
                    createPassword: '',
                    emailRules: [
                        v => !!v || '邮箱必填',
                        v => /.+@.+/.test(v) || '邮箱格式错误',
                    ],
                    userNameRules: [
                     v => !!v || '用户名必填',
                    
                    ],
                    passRules: [
                        v => !!v || '密码必填',
                        v => v.length >= 6 || '至少6个字符'
                    ]

            }
            
        },
        methods:{
          
            register(){
                this.$http.post('/auth/register',{username:this.createUsername,email:this.createEmail,password:this.createPassword},{'Content-Type':'application/json'}).then(res=> {
                        if(res.code==0){
                        this.$toast.success('注册成功');
                        this.registerWindowVisible=false;
                        
                        }
                        else{
                        this.$toast.error('请求失败');
                        }
                    }).catch(function (error) {console.log(error);});
            }
        }
    };
</script>

<style src="./Layout.scss" lang="scss" />
