<template>
  <v-container fluid>
    <div class="tables-basic">
      <v-row no-gutters class="d-flex justify-space-between mt-10 mb-6">
        <h1 class="page-title">{{title}}</h1>
        <!-- :to="`/app/${appid}/release-prepare`" -->
        <v-btn 
        class="primary offset-y"       
        @click="toReleasePrepare"
        >
        {{hasDraft?'修改版本':'创建新版本'}}</v-btn>
      </v-row>
      <h3 style="padding-left:16px;margin-bottom: 0;">版本</h3>
      <v-row>
        <v-col cols="12">
          <v-list>
            <v-list-item  v-if="hasDraft">
              <v-list-item-content>
                <v-list-item-title class="mb-4 versionTitle"> 
                  <span>{{draftVersion.uversion||'未命名版本'}}</span> 
                  <v-btn
                      text
                      color="primary"
                      class="operationBtn"
                      @click='deleteVersion'
                  >
                    舍弃版本
                  </v-btn>
                  
                </v-list-item-title>
                <v-list-item-subtitle>
                  草稿
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  更新时间：{{formatDate(draftVersion.publishDateTime)}}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <template v-if="publishedVersionList.length>0">
              <v-divider></v-divider>
              <v-list-item >
                <v-list-item-content>
                  <v-list-item-title class="mb-4 versionTitle" > 
                    <span>
                      {{currentVersion.uversion}}
                    </span>
                    <div>
                      <v-btn
                        text
                        color="primary"
                        class="operationBtn"
                        @click="setDownloads(currentVersion)"
                      >
                      发布策略
                      </v-btn>
                      <v-btn
                          text
                          color="primary"
                          class="operationBtn"
                          @click="toVersionDetail(currentVersion)"
                      >
                        查看详情
                      </v-btn>
                      <v-btn
                          text
                          color="primary"
                          class="operationBtn"
                          @click="updateVersionState(currentVersion)"
                      >
                        {{currentVersion.state==0?'暂停':'启用'}}
                      </v-btn>
                    </div>
                    
                    <!-- <span class="operationBtn">查看详情</span> -->
                   </v-list-item-title>
                   <v-list-item-subtitle>
                    {{stateObj[currentVersion.state+''] }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    更新时间：{{formatDate(currentVersion.publishDateTime)}}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
           
          </v-list>
        </v-col>
           
           
      </v-row>
      <h3 style="padding-left:16px">版本历史记录</h3>
      <v-simple-table  >
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">发布</th>
            <th class="text-left">版本号</th>
            <th class="text-left">发布日期</th>
            <th class="text-left">版本状态</th>
            <th class="text-left">当前下载量</th>
            <th class="text-left">发布策略</th>               
            <th class="text-left"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in publishedVersionList" :key="item.name" >
            <td>{{ item.uversion }}</td>
            <td style="min-width:80px">{{ item.versionCode }}</td>           
            <td style="min-width:120px">{{ formatDate(item.publishDateTime)}}</td>
            <td style="min-width:80px">{{ stateObj[item.state+''] }}</td> 
            <td style="min-width:100px">{{ item.downloads }}</td>
            <td style="min-width:100px">
              <!-- <span style="margin-right: 12px;">{{item.allowedDownloads==-1?'--':item.allowedDownloads}}</span>
              <v-icon class="mr-3" 
                  color="primary"
                 
                  :size="16"
                  @click="setDownloads(item)" 
                  >mdi-pencil-outline</v-icon> -->
                  <span style="color:rgba(42, 130, 228, 1);cursor: pointer; " @click="setDownloads(item)">{{getPublishs(item.allowedDownloads)}}</span>
            </td>
            <td style="min-width:140px">
              <!-- <v-tooltip bottom>
                <template v-slot:activator="{on,attrs}">
                  <v-icon class="mr-3" 
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  :size="16"
                  @click="setDownloads(item)" 
                  >mdi-pencil-outline</v-icon>
                 
                </template>
                <span>设置最大下载量</span>
              </v-tooltip> -->
              <!-- <v-icon class="mr-3">mdi-arrow-right</v-icon> -->
             
              <v-tooltip bottom>
                <template v-slot:activator="{on,attrs}">
                  <v-icon class="mr-3" 
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click="showQrcode(item)" 
                  >mdi-qrcode-scan</v-icon>
                </template>
                <span>二维码</span>
              </v-tooltip>
                    <v-btn
                          text
                          color="primary"
                          class="operationBtn"
                          @click="updateVersionState(item)"
                          style="padding-left:0;"
                      >
                        {{item.state==0?'暂停':'启用'}}
                      </v-btn>
                      <v-tooltip bottom>
                        <template v-slot:activator="{on,attrs}">
                          <v-icon class="mr-3" 
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                          @click="toVersionDetail(item)" 
                          >mdi-arrow-right</v-icon>
                        </template>
                        <span>查看版本详情</span>
                      </v-tooltip>
            </td>
           
          </tr>
          </tbody>
        </template>
      </v-simple-table>
      <template v-if="total>0">
        <v-pagination 
          v-model="postData.current"
          :length="total"
          :total-visible="7"
          @input="getVersionList"
        ></v-pagination>
      </template>
     <template v-else>
        <p class="noData">暂无数据</p>
     </template>
      
    </div>
 
      <el-dialog
            title="设置最大下载量"
            :visible.sync="downloadWindowVisible"
            width="500px"   
            :close-on-click-modal="false"
        >
        <v-card >
        
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-checkbox
                    v-model="isUnLimit"
                    label="全量发布"
                  ></v-checkbox>
                </v-col>
              
              </v-row>
              <v-row v-if="!isUnLimit">
                <v-col cols="3">
                  <div class="mt-3 body-2" for="downloadCount">最大下载量</div>
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    id="downloadCount"
                    outlined
                    single-line
                    full-width
                    label=""
                  type="number"
                    v-model="allowedDownloads"
                    :min="0"
                    :height="32"
                  ></v-text-field>
                
                </v-col>
              </v-row>
            
            </v-container>
          </v-card-text>
        
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="darken-1"
              text
              @click="downloadWindowVisible = false"
            >
              取消
            </v-btn>
            <v-btn
              color="primary darken-1"
              text
              @click="saveDownloads"
            >
              保存
            </v-btn>
          </v-card-actions>
        </v-card>
      </el-dialog>
      <el-dialog
          title="二维码"
          :visible.sync="qrcodeWindowVisible"
          width="500px"   
          :close-on-click-modal="false"
      >
      <v-card>
      
        <v-card-text style="padding-bottom: 0;">
          <v-container>
            <v-row>
              <v-col cols="12" class="qrCodeContainer">
                <vue-qr  :text="selectedItem.apkUrl" :options="{ width: 200, height: 200 }"></vue-qr>

                <span>下载地址：</span>
                <div class="address">
                  
                  <span>{{selectedItem.apkUrl}}</span>
                  <v-tooltip bottom>
                    <template v-slot:activator="{on,attrs}">                   
                      <v-icon 
                      id="copyAddressIcon"
                      class="mr-3" 
                      v-bind="attrs"
                      v-on="on"
                      :data-clipboard-text="selectedItem.apkUrl"                  
                      @click="copyAddress" 
                      >mdi-content-copy</v-icon>
                    </template>
                    <span>点击复制</span>
                  </v-tooltip>
                 
                </div>
               
                <a :href="selectedItem.apkUrl">
                  <v-btn color="primary">
                      立即下载
                  </v-btn>
              </a>
              </v-col>
            
            </v-row>
            
          
          </v-container>
        </v-card-text>
      
        
      </v-card>
    </el-dialog>
  </v-container>
</template>

<script>

import Clipboard from "clipboard";
import VueQr from 'vue-qr';
export default {
  name: "Production",
  props:{
    title:{
      type:String,
      default:''
    },
    type:{
        type:Number,
        default:0
    }
  },
  components: {
    VueQr
  },
  data() {
    return {
      qrcodeWindowVisible:false,
      isUnLimit:true,
      downloadWindowVisible:false,
      allowedDownloads:0,
      publishedVersionList:[],
      hasDraft:false,
      publishs:[
        {lable:'全量发布(所有国家/地区)',value:-1},
        {lable:'全量发布(开放国家/地区)',value:-2},
        {lable:'限量发布(所有国家/地区)',value:0},
        {lable:'限量发布(开放国家/地区)',value:-3},
        {lable:'暂停发布',value:-4},
      ],
      app: {
        icon: "mdi-mdi-apps",
        appid: "com.sinognss.sm.free",
        name: "Survey Master",
        version: {
          name: "2.7.5.200112",
          code: 477,
        },
      },
      postData:{
        aid:'',
        type:1,
        current:1,
        size:10
      },
      draftVersion:{},
      currentVersion:{},
      routeVersionDetail:{
        0:'productionVersionDetail',
        1:'internalTestingVersionDetail',
        2:'openTestingVersionDetail'
      },
      setDownloadItem:null,
      stateObj:{
        '0':'已发布',
        '-1':'草稿',
        '-2':'暂停'
      },
      total:0,
      selectedItem:{
        qrCodeUrl:'',
        apkUrl:''
      }
    };
  },
  computed:{
    appid(){return this.$route.params.id},
  },
  mounted(){
    this.postData.aid=this.appid;
    this.postData.type=this.type;
    this.getVersionList();
  },
  methods:{
    getPublishs(value){
      let obj=this.publishs.find(i=>i.value==value)
      return obj?obj.lable:'限量发布(所有国家/地区)'
    },

    copyAddress(){
      var clipboard = new Clipboard("#copyAddressIcon");
      clipboard.on("success", ()=> {
        this.$toast.success("复制成功");

        // 释放内存
        clipboard.destroy();
      });

      clipboard.on("error", ()=> {
        // 不支持复制
        this.$toast.error(`该浏览器不支持自动复制`);
        
        // 释放内存
        clipboard.destroy();
      });
    },
    showQrcode(item){
      console.log(item);
      this.selectedItem=item;
      this.qrcodeWindowVisible=true;
    },
    updateVersionState(item){
      let updateState=item.state==0?-2:0;
      let keyword=item.state==0?'暂停':'启用';
      this.$http.post('/api/app/release/state/update',{versionId:item.id,state:updateState}).then(res=> {
                if(res.code==0){
                  this.$toast.success(`${keyword}应用成功`);
                  this.getVersionList();
                }
                else{
                  this.$toast.error(`${keyword}应用失败`);
                }
            }).catch(function (error) {console.log(error);});
    },
    setDownloads(item){

      // if(!item.apkSize) {
      //   return this.$toast.error('版本未上传文件')
      // }
      this.$router.push({
        name:'DownloadLimit',
        query:{
          id:item.id,
          PluginType:item.allowedDownloads>=0?0:item.allowedDownloads,
          limit:item.allowedDownloads,
        },
      });
    },
    saveDownloads(){

      this.isUnLimit?this.allowedDownloads=-1:'';
      this.$http.post('/api/app/release/allowedDownloads',{versionId:this.setDownloadItem.id,allowedDownloads:this.allowedDownloads}).then(res=> {
                if(res.code==0){
                  this.$toast.success(`版本最大下载量设置成功`);
                  this.downloadWindowVisible=false;
                  this.getVersionList();
                }
                else{
                  this.$toast.error(`版本最大下载量设置失败`);
                  
                }
            }).catch(function (error) {console.log(error);});
      },
    toVersionDetail(item){
        this.$router.push({
          name:this.routeVersionDetail[this.type],
          params:{
            prepareId:item.id,
           
            type:this.type
          }
        })
    },
    getVersionList(){
      this.$http.post('/api/app/release/list',this.postData).then(res=> {
                if(res.code==0){
                   let allVersionList=res.data.records;
                   this.draftVersion=allVersionList.find(i=>i.state==-1);
                   this.hasDraft=this.draftVersion!=undefined;
                    this.total=Math.ceil(res.data.total/this.postData.size);
                   this.publishedVersionList=allVersionList.filter(i=>i.state!=-1);
                   let currentVersionTime=Math.max(...this.publishedVersionList.map(item => { return item.publishDateTime}));
                   this.currentVersion=this.publishedVersionList.find(i=>i.publishDateTime==currentVersionTime);
                }
                else{
                  this.$toast.error('请求失败');
                   
                }
            }).catch(function (error) {console.log(error);});
    },
    deleteVersion(){
      this.$http.post('/api/app/release/remove',{versionId:this.draftVersion.id}).then(res=> {
                if(res.code==0){
                  this.$toast.success('版本舍弃成功'); 
                  this.getVersionList();
                }
                else{
                  this.$toast.error('版本删除失败');
                   
                }
            }).catch(function (error) {console.log(error);});
    },
    toReleasePrepare(){
      if(this.hasDraft){
                switch(this.type){
                        case 0:                        
                            this.$router.push({
                                name:'ReleasePrepare',
                                params:{prepareId:this.draftVersion.id,draft:this.draftVersion},
                                //query:{draft:JSON.stringify(this.draftVersion)}
                            });
                            break;
                        case 1:
                          this.$router.push({
                                name:'internalTestingRelease',
                                params:{prepareId:this.draftVersion.id,draft:this.draftVersion}
                            });
                            break;
                            
                        case 2:
                          this.$router.push({
                                name:'openTestingRelease',
                                params:{prepareId:this.draftVersion.id,draft:this.draftVersion}
                            });
                            break;
                           
                    }
      }else{
        let createPostData={
          aid:this.appid,
          type:this.type
        }
        this.$http.post('/api/app/release/create',createPostData).then(res=> {
                  if(res.code==0){
                    let currentId=res.data.versionId;
                    switch(this.type){
                        case 0:                                                
                            this.$router.push({
                                name:'ReleasePrepare',
                                params:{prepareId:currentId},

                            });
                            break;
                        case 1:                          
                            this.$router.push({
                                name:'internalTestingRelease',
                                params:{prepareId:currentId}
                            });
                            break;
                        case 2:                         
                            this.$router.push({
                                name:'openTestingRelease',
                                params:{prepareId:currentId}
                            });
                            break;
                        
                    }
                  }
                  else{
                    this.$toast.error('请求失败');
                    
                  }
              }).catch(function (error) {console.log(error);}); 
        
        }
      
      }
  }
};
</script>

<style src="./index.scss" lang="scss"></style>
