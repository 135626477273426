<template>
  <v-container fluid>
    <div class="tables-basic">
      <v-row no-gutters class="d-flex justify-space-between mt-10 mb-6">
        <h1 class="page-title">
          {{ draft ? "修改" + title : "创建" + title }}
        </h1>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card class="pa-4">
            <v-row>
              <v-col cols="12">
                <h3>APK</h3>
                <div class="text-center">
                  <div class="file-area rounded">
                    <!-- <div
                      v-show="dropActive"
                      class="drop-area-active rounded"
                    ></div> -->

                    <div>
                      <!-- <v-img
                        src="@/assets/file.svg"
                        height="72"
                        width="72"
                        class="mx-auto"
                      ></v-img>
                      <v-card-text>将 APK 拖至此处即可上传</v-card-text>

                      <v-btn color="primary" text @click="selectFile">
                        <v-icon>mdi-upload</v-icon>
                        上传
                        <label
                          for="app-file"
                          class="file-upload px-2 py-1"
                          ref="selectLabel"
                          cousor="pointer"
                        ></label>
                      </v-btn>
                      <v-file-upload
                        @input="uploadFile"
                        input-id="app-file"
                        post-action="http://localhost:8080/api/app/release/upload"
                        :multiple="false"
                        :drop="true"
                        v-model="files"
                        ref="upload"
                        @drop-changed="dropChanged"
                        @active-changed="activeChanged"
                        :data="uploadFileData"
                      >
                      </v-file-upload> -->
                      <el-upload
                        class="upload-demo"
                        drag
                        action="/api/app/release/upload"
                        :data="uploadFileData"
                        :headers="uploadFileHeaders"
                        :on-success="uploadSuccess"
                        :on-remove="removeFile"
                        :file-list="fileList"
                        :multiple="false"
                        :limit="1"
                      >
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">
                          将APK拖到此处，或<em>点击上传</em>
                        </div>
                        <!-- <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div> -->
                      </el-upload>
                    </div>

                    <!-- <div
                      v-if="uploading"
                      class="uploading-area-active rounded d-flex flex-column align-center justify-center"
                    >
                      <div
                        v-show="files.length"
                        v-for="file in files"
                        v-bind:todo="file"
                        v-bind:key="file.name"
                      >
                        <v-progress-circular
                          indeterminate
                          color="green"
                        ></v-progress-circular>
                        <p>正在上传"{{ file.name }}"</p>
                        <v-btn
                          depressed
                          color="primary--text"
                          @click="cancelUpload"
                          >取消</v-btn
                        >
                      </div>
                    </div> -->
                  </div>
                </div>
              </v-col>
            </v-row>

            <div>
              <v-row>
                <v-col cols="12">
                  <h3>版本详情</h3>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2">
                  <div class="mt-3 body-2" for="verName">版本名称 *</div>
                </v-col>
                <v-col lg="5" sm="10">
                  <v-text-field
                    id="verName"
                    outlined
                    single-line
                    full-width
                    label=""
                    :maxlength="50"
                    counter="50"
                    v-model="versionObj.uVersionName"
                  ></v-text-field>
                  <p class="caption">
                    此名称旨在让您可以识别这个版本，不会向最终用户显示。我们根据该版本的第一个
                    app bundle 或 APK 提供了建议的名称，不过您可以修改此名称。
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2">
                  <div class="mt-3 body-2" for="verName">版本说明</div>
                </v-col>
                <v-col cols="10">
                  <!-- <v-textarea
                    height="210"
                    id="verDesc"
                    outlined
                    full-width
                    label=""
                    v-model="versionObj.log"
                  ></v-textarea> -->
                  <!-- <quill-editor ref="myTextEditor" v-model="versionObj.log" style="height:300px;"></quill-editor> -->
                  <mavon-editor v-model="versionObj.log" />
                  <p class="caption">
                    已提供 {{ verDescLangs }} 种语言的版本说明
                  </p>
                  <p class="caption">
                    向用户介绍此版本的功能和特性。在相应的语言标签内输入每种语言的版本说明。
                  </p>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-app-bar fixed class="pr-5" height="68" color="white" bottom>
      <v-spacer></v-spacer>
      <v-btn class="mr-2" @click="toVersionList" text> 舍弃更改</v-btn>
      <v-btn class="mr-2" text @click="requestVersion(0)"> 保存</v-btn>

      <v-btn elevation="0" @click="requestVersion(1)">发布策略</v-btn>
    </v-app-bar>
    <!-- <el-dialog
            title="设置最大下载量"
            :visible.sync="downloadWindowVisible"
            width="500px"   
            :close-on-click-modal="false"
        >
   
    <v-card >
     
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-checkbox
                v-model="isUnLimit"
                label="全量发布"
              ></v-checkbox>
            </v-col>
          
          </v-row>
          <v-row v-if="!isUnLimit">
            <v-col cols="3">
              <div class="mt-3 body-2" for="downloadCount">最大下载量</div>
            </v-col>
            <v-col cols="9">
              <v-text-field
                id="downloadCount"
                outlined
                single-line
                full-width
                label=""
               type="number"
                v-model="allowedDownloads"
                :min="0"
                :height="32"
              ></v-text-field>
             
            </v-col>
          </v-row>
         
        </v-container>
      </v-card-text>
     
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="darken-1"
          text
          @click="cancelSetDownloads"
        >
          取消
        </v-btn>
        <v-btn
          color="primary darken-1"
          text
          @click="saveDownloads"
        >
          保存
        </v-btn>
      </v-card-actions>
    </v-card>
  </el-dialog> -->
  </v-container>
</template>
<style></style>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    type: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      isUnLimit: true,
      downloadWindowVisible: false,
      allowedDownloads: 0,
      fileList: [],
      files: [],
      dropActive: false,
      uploading: false,

      verDescLangs: 2, //当前版本提供多少种语言描述版本更新内容

      verName: {},
      versionObj: {
        versionId: "",
        uVersionName: "",
        // log:''
        log: "",
      },
      versionLogObj: {
        "en-us": "请在此处输入或粘贴en-US版的版本说明",
        "zh-cn": "请在此处输入或粘贴zh-CN版的版本说明",
      },
      logContent: "",
    };
  },
  computed: {
    versionId() {
      return this.$route.params.prepareId;
    },
    draft() {
      return this.$route.params.draft;
    },
    uploadFileData() {
      return { versionId: this.versionId };
    },
    uploadFileHeaders() {
      return { Authorization: window.localStorage.getItem("token") };
    },
  },
  mounted() {
    this.versionObj.versionId = this.versionId;
    if (this.draft) {
      this.versionObj.uVersionName = this.draft.uversion;

      this.draft.versionLogObj["zh-cn"]
        ? (this.versionLogObj["zh-cn"] = this.draft.versionLogObj["zh-cn"])
        : "";
      this.draft.versionLogObj["en-us"]
        ? (this.versionLogObj["en-us"] = this.draft.versionLogObj["en-us"])
        : "";
      if (this.draft.versionName) {
        this.fileList = [{ name: this.draft.versionName }];
      } else {
        this.fileList = [];
      }
    }
    this.versionObj.log = `<zh-CN>\n${this.versionLogObj["zh-cn"]}\n</zh-CN>\n<en-US>\n${this.versionLogObj["en-us"]}\n</en-US>`;
  },
  methods: {
    cancelSetDownloads() {
      this.downloadWindowVisible = false;
      this.toVersionList();
    },
    saveDownloads() {
      this.isUnLimit ? (this.allowedDownloads = -1) : "";
      this.$http
        .post("/api/app/release/allowedDownloads", {
          versionId: this.versionId,
          allowedDownloads: this.allowedDownloads,
        })
        .then((res) => {
          if (res.code == 0) {
            this.$toast.success(`版本最大下载量设置成功`);
            this.downloadWindowVisible = false;
            this.toVersionList();
          } else {
            this.$toast.error(`版本最大下载量设置失败`);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    uploadSuccess(response, file, fileList) {
      console.log(response, file, fileList);
      if (response.code == 0) {
        this.$toast.success(`上传成功`);
        this.fileList = fileList;
        this.versionObj.uVersionName = response.data.versionName;
      } else {
        this.$toast.error(response.message);
        this.fileList = [];
      }
    },
    removeFile() {
      this.$http
        .post("/api/app/release/file/delete", { versionId: this.versionId })
        .then((res) => {
          if (res.code == 0) {
            this.$toast.success(`文件删除成功`);
          } else {
            this.$toast.error(`文件删除失败`);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    requestVersion(type) {
      let url = "",
        keyword = "";

      if (type == 0) {
        url = "/api/app/release/save";
        keyword = "保存";
      } else {
        url = "/api/app/release/publish";
        keyword = "发布";
      }

      this.$http
        .post(url, this.versionObj)
        .then((res) => {
          if (res.code == 0) {
            this.$toast.success(`版本信息${keyword}成功`);
            if (type == 0) {
              this.toVersionList();
            } else {
              this.$router.push({
                name: "DownloadLimit",
                query: {
                  id: this.versionId,
                  PluginType:this.allowedDownloads >= 0 ? 0 : this.allowedDownloads,
                  limit: this.allowedDownloads,
                },
              })
            }
          } else {
            this.$toast.error(res.message);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    toVersionList() {
      switch (this.type) {
        case 0:
          this.$router.push({ name: "Tracks" });
          break;
        case 1:
          this.$router.push({ name: "internalTesting" });
          break;
        case 2:
          this.$router.push({ name: "openTesting" });
          break;
      }
    },
    selectFile: function() {
      this.$refs.selectLabel.click();
    },
    uploadFile: function(value) {
      this.files = value;
      var file = value[0];
      //this.uploading = true
      this.$refs.upload.update(file, { active: true });
    },
    dropChanged: function(active) {
      this.dropActive = active;
    },
    activeChanged: function(active) {
      console.log("activeChanged:" + active);
      this.uploading = active;
    },
    cancelUpload: function() {
      this.uploading = false;
    },
  },
};
</script>

<style src="./index.scss" lang="scss"></style>
