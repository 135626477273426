<template>
    <div style="height:100%">
        <store-header :title="appStore.appZhName" :appid="appid" :appIcon="appStore.iconUrl" @typechange="typeChange"></store-header>
        <div class="appStoreContainer" v-if="appStoreType==0">
            <!-- <div class="headerContainer">
                <div class="left">
                    <strong>{{appStore.appZhName}}</strong>
                </div>
                <div class="right">
                    <v-btn text color="#fff">
                        首页
                    </v-btn>
                    <v-btn text color="#fff">
                        更新
                    </v-btn>
                    <span class="android">
                        <v-icon  
                        color="#fff"
                      
                        >mdi-android</v-icon>
                        Android
                    </span>
                </div>
            </div> -->
            <!-- <store-header :title="appStore.appZhName" :route="$route"></store-header> -->
            <div class="contentContainer">
                <h1 class="title">{{appStore.appZhName}}</h1>
                <vue-qr  :text="appStore.apkUrl" :options="{ width: 200, height: 200 }"></vue-qr>
                <p class="version">当前版本：{{appStore.aversion}}</p>
                <a :href="appStore.apkUrl">
                    <v-btn color="#fff">
                        立即下载
                    </v-btn>
                </a>
            </div>
           
           
        </div>
        <Log v-if="appStoreType==1"></Log>
    </div>
    
</template>

<script>
import './index.scss'
import Log from "../Log/index";
import VueQr from 'vue-qr';

import StoreHeader from "../../../components/StoreHeader/index";
export default {
   
    data() {
        return {
            appStore:{
                appZhName:'11',
                qrCodeUrl:'',
                uversion:'11'
            }
        }
    },
    components: {  
        Log,
        StoreHeader,
        VueQr
    },
    computed:{
        appid(){return this.$route.query.appid},
        // appid(){return this.$store.state.appDetail.appid},
        appStoreType(){return this.$store.state.appStoreType},
    },
    watch:{
        appStoreType(val){
            if(val==0){
                this.getAppDetail();
            }
        }
    },
    mounted(){
    //   if(this.appStoreType==0){
    //     this.getAppDetail();
    //   }
      this.getAppDetail();
    },
    methods:{
        typeChange(val){
            this.$store.commit("setAppStoreType",val);
        },
        getAppDetail(){
            this.$http.post('/api/apps/details',{appid:this.appid}).then(res=> {
                if(res.code==0){
                  this.appStore=res.data;
                  document.title=this.appStore.appZhName;       
                    document.getElementsByTagName('head')[0].querySelector('link[rel="icon"]').href=this.appStore.iconUrl;
                }
                else{
                  this.$toast.error(`请求失败`);
                }
            }).catch(function (error) {console.log(error);});
        },
       
    }
}
</script>