var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"dashboard-page"},[_c('v-row',{staticClass:"d-flex justify-space-between mt-10 mb-6",attrs:{"no-gutters":""}},[_c('h1',{staticClass:"page-title"},[_vm._v("Dashboard")]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize button-shadow mr-1",attrs:{"color":"secondary"}},'v-btn',attrs,false),on),[_vm._v("Latest Reports")])]}}])})],1),_c('v-row',[_c('v-col',{attrs:{"lg":"3","sm":"6","md":"5","cols":"12"}},[_c('v-card',{staticClass:"mx-1 mb-1"},[_c('v-card-title',{staticClass:"pa-6 pb-3"},[_c('p',[_vm._v("Visits Today")]),_c('v-spacer'),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"textColor"}},[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',_vm._l((_vm.mock.menu),function(item,i){return _c('v-list-item',{key:i,on:{"click":function () {}}},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1)],1),_c('v-card-text',{staticClass:"pa-6 pt-0"},[_c('v-row',{staticClass:"pb-5",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"my-auto",attrs:{"cols":"5"}},[_c('span',{staticClass:"font-weight-medium card-dark-grey",staticStyle:{"font-size":"24px"}},[_vm._v("12, 678")])]),_c('v-col',{attrs:{"cols":"6"}},[_c('Trend',{attrs:{"data":_vm.getRandomDataForTrends(),"gradient":_vm.mock.trend.gradient,"height":40,"stroke-width":"4","smooth":""}})],1)],1),_c('v-row',{staticClass:"justify-space-between pb-3",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"5"}},[_c('div',{staticClass:"card-light-grey"},[_vm._v("Registrations")]),_c('div',{staticClass:"text-h6 card-dark-grey font-weight-regular"},[_vm._v(" 860 ")])]),_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"card-light-grey"},[_vm._v("Sign Out")]),_c('div',{staticClass:"text-h6 card-dark-grey font-weight-regular"},[_vm._v(" 32 ")])]),_c('v-col',{attrs:{"cols":"4","xl":"2"}},[_c('div',{staticClass:"text-right card-light-grey"},[_vm._v("Rate")]),_c('div',{staticClass:"text-right text-h6 card-dark-grey font-weight-regular"},[_vm._v(" 3.25% ")])])],1)],1)],1)],1),_c('v-col',{attrs:{"lg":"3","sm":"6","md":"7","cols":"12"}},[_c('v-card',{staticClass:"mx-1 mb-1"},[_c('v-card-title',{staticClass:"pa-6 pb-3"},[_c('p',[_vm._v("App Performance")]),_c('v-spacer'),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"textColor"}},[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',_vm._l((_vm.mock.menu),function(item,i){return _c('v-list-item',{key:i,on:{"click":function () {}}},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1)],1),_c('v-card-text',{staticClass:"pa-6 pt-0"},[_c('v-row',{staticClass:"pb-5",attrs:{"no-gutters":""}},[_c('div',{staticClass:"mr-4"},[_c('v-icon',{staticClass:"ml-n2",attrs:{"color":"primary"}},[_vm._v(" mdi-circle-medium ")]),_c('span',{staticClass:"card-light-grey"},[_vm._v("Integration")])],1),_c('div',[_c('v-icon',{attrs:{"color":"warning"}},[_vm._v(" mdi-circle-medium ")]),_c('span',{staticClass:"card-light-grey"},[_vm._v("SDK")])],1)]),_c('v-row',{staticClass:"pb-3",attrs:{"no-gutters":""}},[_c('v-col',[_c('div',{staticClass:"text-h6 card-light-grey font-weight-regular"},[_vm._v(" Integration ")]),_c('v-progress-linear',{attrs:{"value":_vm.value,"background-color":"#ececec","color":"primary"}})],1)],1),_c('v-row',{staticClass:"pb-1",attrs:{"no-gutters":""}},[_c('v-col',[_c('div',{staticClass:"text-h6 card-light-grey font-weight-regular"},[_vm._v(" SDK ")]),_c('v-progress-linear',{attrs:{"value":_vm.value2,"background-color":"#ececec","color":"warning"}})],1)],1)],1)],1)],1),_c('v-col',{attrs:{"lg":"3","sm":"6","md":"7","cols":"12"}},[_c('v-card',{staticClass:"mx-1 mb-1",staticStyle:{"min-height":"228px"}},[_c('v-card-title',{staticClass:"pa-6 pb-3"},[_c('p',[_vm._v("Server Overview")]),_c('v-spacer'),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"textColor"}},[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',_vm._l((_vm.mock.menu),function(item,i){return _c('v-list-item',{key:i,on:{"click":function () {}}},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1)],1),_c('v-card-text',{staticClass:"pa-6 pt-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"my-auto text-truncate",attrs:{"cols":"6","md":"5","lg":"6","xl":"4"}},[_c('span',[_vm._v("60% / 37°С / 3.3 Ghz")])]),_c('v-col',{attrs:{"cols":"6","md":"7","lg":"6","xl":"8"}},[(_vm.apexLoading)?_c('ApexChart',{attrs:{"height":"35","type":"area","options":_vm.mock.apexArea1.options,"series":_vm.mock.apexArea1.series}}):_vm._e()],1)],1),_c('v-row',{staticClass:"my-3",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"my-auto text-truncate",attrs:{"cols":"6","md":"5","lg":"6","xl":"4"}},[_c('span',[_vm._v("54% / 31°С / 3.3 Ghz")])]),_c('v-col',{attrs:{"cols":"6","md":"7","lg":"6","xl":"8"}},[(_vm.apexLoading)?_c('ApexChart',{attrs:{"height":"35","type":"area","options":_vm.mock.apexArea2.options,"series":_vm.mock.apexArea2.series}}):_vm._e()],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"my-auto text-truncate",attrs:{"cols":"6","md":"5","lg":"6","xl":"4"}},[_c('span',[_vm._v("57% / 21°С / 3.3 Ghz")])]),_c('v-col',{attrs:{"cols":"6","md":"7","lg":"6","xl":"8"}},[(_vm.apexLoading)?_c('ApexChart',{attrs:{"height":"35","type":"area","options":_vm.mock.apexArea3.options,"series":_vm.mock.apexArea3.series}}):_vm._e()],1)],1)],1)],1)],1),_c('v-col',{attrs:{"lg":"3","sm":"6","md":"5","cols":"12"}},[_c('v-card',{staticClass:"mx-1 mb-1"},[_c('v-card-title',{staticClass:"flex-nowrap pa-6 pb-3"},[_c('p',{staticClass:"text-truncate"},[_vm._v("Revenue Breakdown")]),_c('v-spacer'),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"textColor"}},[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',_vm._l((_vm.mock.menu),function(item,i){return _c('v-list-item',{key:i,on:{"click":function () {}}},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1)],1),_c('v-card-text',{staticClass:"pa-6 pt-0 mb-1"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('ApexChart',{staticClass:"mt-1",attrs:{"height":"124","type":"donut","options":_vm.mock.apexPie.options,"series":_vm.generatePieSeries()}})],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mx-1 mb-1"},[_c('v-card-title',{staticClass:"pa-6 pb-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"7","sm":"4","md":"4","lg":"5"}},[_c('p',[_vm._v("Daily Line Chart")])]),_c('v-col',{staticClass:"d-none d-sm-flex align-center",attrs:{"sm":"6","md":"6","lg":"5"}},[_c('v-icon',{attrs:{"size":"18","color":"warning"}},[_vm._v("mdi-circle-medium")]),_c('span',{staticClass:"card-dark-grey font-weight-regular mr-3",staticStyle:{"font-size":"18px"}},[_vm._v("Tablet")]),_c('v-icon',{attrs:{"size":"18","color":"primary"}},[_vm._v("mdi-circle-medium")]),_c('span',{staticClass:"card-dark-grey font-weight-regular mr-3",staticStyle:{"font-size":"18px"}},[_vm._v("Mobile")]),_c('v-icon',{attrs:{"size":"18","color":"#B1BCFF"}},[_vm._v("mdi-circle-medium")]),_c('span',{staticClass:"card-dark-grey font-weight-regular",staticStyle:{"font-size":"18px"}},[_vm._v("Desktop")])],1),_c('v-col',{attrs:{"cols":"5","sm":"2","md":"2","lg":"1","offset-lg":"1"}},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-select',_vm._g(_vm._b({staticClass:"main-chart-select",attrs:{"dense":"","flat":"","single-line":"","hide-details":"","items":_vm.mock.select,"outlined":""},model:{value:(_vm.mainApexAreaSelect),callback:function ($$v) {_vm.mainApexAreaSelect=$$v},expression:"mainApexAreaSelect"}},'v-select',attrs,false),on))]}}])})],1)],1)],1),_c('v-card-text',{staticClass:"pa-6"},[_c('v-row',[_c('v-col',[(_vm.apexLoading)?_c('ApexChart',{attrs:{"height":"350","type":"area","options":_vm.mock.mainApexArea.options,"series":_vm.mainApexAreaSelect === 'Daily'
                      ? _vm.mock.mainApexArea.series
                      : _vm.mainApexAreaSelect === 'Weekly'
                      ? _vm.mock.mainApexArea.series2
                      : _vm.mock.mainApexArea.series3}}):_vm._e()],1)],1)],1)],1)],1),_c('v-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('v-card',{staticClass:"mx-1 mb-1"},[_c('v-card-title',{staticClass:"pa-6 pb-3"},[_c('p',[_vm._v("Light Blue")]),_c('v-spacer'),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"textColor"}},[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',_vm._l((_vm.mock.menu),function(item,i){return _c('v-list-item',{key:i,on:{"click":function () {}}},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1)],1),_c('v-card-text',{staticClass:"pa-6 pt-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"my-auto",attrs:{"cols":"6"}},[_c('span',{staticStyle:{"font-size":"42px"}},[_vm._v("199 "),_c('span',{staticClass:"caption error--text"},[_vm._v("-3.7%")])])]),_c('v-col',{attrs:{"cols":"6"}},[(_vm.apexLoading)?_c('ApexChart',{attrs:{"height":"100","type":"bar","options":_vm.mock.apexBar1.options,"series":_vm.mock.apexBar1.series}}):_vm._e()],1)],1),_c('v-row',{staticClass:"justify-space-between",attrs:{"no-gutters":""}},[_c('div',[_c('div',{staticClass:"subtext"},[_vm._v(" 33 "),_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-arrow-top-right")])],1),_c('div',{staticClass:"subtext-index"},[_vm._v("Registrations")])]),_c('div',[_c('div',{staticClass:"subtext"},[_vm._v(" 3.25%"),_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-arrow-top-right")])],1),_c('div',{staticClass:"subtext-index"},[_vm._v("Bounce Rate")])]),_c('div',[_c('div',{staticClass:"subtext"},[_vm._v(" 330"),_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-arrow-bottom-right")])],1),_c('div',{staticClass:"subtext-index"},[_vm._v("Views")])])])],1)],1)],1),_c('v-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('v-card',{staticClass:"mx-1 mb-1"},[_c('v-card-title',{staticClass:"pa-6 pb-3"},[_c('p',[_vm._v("Sing App")]),_c('v-spacer'),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"textColor"}},[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',_vm._l((_vm.mock.menu),function(item,i){return _c('v-list-item',{key:i,on:{"click":function () {}}},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1)],1),_c('v-card-text',{staticClass:"pa-6 pt-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"my-auto",attrs:{"cols":"7"}},[_c('span',{staticStyle:{"font-size":"42px"}},[_vm._v("121 "),_c('span',{staticClass:"error--text caption"},[_vm._v("-3.2%")])])]),_c('v-col',{attrs:{"cols":"5"}},[(_vm.apexLoading)?_c('ApexChart',{attrs:{"height":"100","type":"bar","options":_vm.mock.apexBar2.options,"series":_vm.mock.apexBar2.series}}):_vm._e()],1)],1),_c('v-row',{staticClass:"justify-space-between",attrs:{"no-gutters":""}},[_c('div',[_c('div',{staticClass:"subtext"},[_vm._v(" 15"),_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-arrow-top-right")])],1),_c('div',{staticClass:"subtext-index"},[_vm._v("Registrations")])]),_c('div',[_c('div',{staticClass:"subtext"},[_vm._v(" 3.01%"),_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-arrow-top-right")])],1),_c('div',{staticClass:"subtext-index"},[_vm._v("Bounce Rate")])]),_c('div',[_c('div',{staticClass:"subtext"},[_vm._v(" 302"),_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-arrow-top-right")])],1),_c('div',{staticClass:"subtext-index"},[_vm._v("Views")])])])],1)],1)],1),_c('v-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('v-card',{staticClass:"mx-1 mb-1"},[_c('v-card-title',{staticClass:"pa-6 pb-3"},[_c('p',[_vm._v("RNS")]),_c('v-spacer'),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"textColor"}},[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',_vm._l((_vm.mock.menu),function(item,i){return _c('v-list-item',{key:i,on:{"click":function () {}}},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1)],1),_c('v-card-text',{staticClass:"pa-6 pt-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"my-auto",attrs:{"cols":"7"}},[_c('span',{staticStyle:{"font-size":"42px"}},[_vm._v("175 "),_c('span',{staticClass:"error--text caption"},[_vm._v("-3.1%")])])]),_c('v-col',{attrs:{"cols":"5"}},[(_vm.apexLoading)?_c('ApexChart',{attrs:{"height":"100","type":"bar","options":_vm.mock.apexBar3.options,"series":_vm.mock.apexBar3.series}}):_vm._e()],1)],1),_c('v-row',{staticClass:"justify-space-between",attrs:{"no-gutters":""}},[_c('div',[_c('div',{staticClass:"subtext"},[_vm._v(" 31 "),_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-arrow-bottom-right")])],1),_c('div',{staticClass:"subtext-index"},[_vm._v("Registrations")])]),_c('div',[_c('div',{staticClass:"subtext"},[_vm._v(" 3.23%"),_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-arrow-top-right")])],1),_c('div',{staticClass:"subtext-index"},[_vm._v("Bounce Rate")])]),_c('div',[_c('div',{staticClass:"subtext"},[_vm._v(" 301"),_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-arrow-top-right")])],1),_c('div',{staticClass:"subtext-index"},[_vm._v("Views")])])])],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"support-requests mx-1 mb-1"},[_c('v-card-title',{staticClass:"pa-6 pb-0"},[_c('p',[_vm._v("Support Requests")]),_c('v-spacer'),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"textColor"}},[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',_vm._l((_vm.mock.menu),function(item,i){return _c('v-list-item',{key:i,on:{"click":function () {}}},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1)],1),_c('v-card-text',{staticClass:"pa-0"},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',{staticClass:"pl-2"},[_c('tr',[_c('th',{staticClass:"text-left pa-6"},[_vm._v("NAME")]),_c('th',{staticClass:"text-left"},[_vm._v("EMAIL")]),_c('th',{staticClass:"text-left"},[_vm._v("PRODUCT")]),_c('th',{staticClass:"text-left"},[_vm._v("PRICE")]),_c('th',{staticClass:"text-left"},[_vm._v("DATE")]),_c('th',{staticClass:"text-left"},[_vm._v("CITY")]),_c('th',{staticClass:"text-left"},[_vm._v("STATUS")])])]),_c('tbody',_vm._l((_vm.mock.table),function(item){return _c('tr',{key:item.name},[_c('td',{staticClass:"pa-6"},[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(item.email))]),_c('td',[_vm._v(_vm._s(item.product))]),_c('td',[_vm._v(_vm._s(item.price))]),_c('td',[_vm._v(_vm._s(item.date))]),_c('td',[_vm._v(_vm._s(item.city))]),(item.status === 'Sent')?_c('td',[_c('v-chip',{staticClass:"ma-2 ml-0",attrs:{"link":"","color":"success"}},[_vm._v(" Sent ")])],1):(item.status === 'Pending')?_c('td',[_c('v-chip',{staticClass:"ma-2 ml-0",attrs:{"link":"","color":"warning"}},[_vm._v(" Pending ")])],1):(item.status === 'Declined')?_c('td',[_c('v-chip',{staticClass:"ma-2 ml-0",attrs:{"link":"","color":"secondary"}},[_vm._v(" Declined ")])],1):_vm._e()])}),0)]},proxy:true}])})],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }