export default {
    employeeTable: {
        selected: [],
        search: '',
        headers: [
            {
                text: 'Name',
                align: 'start',
                sortable: true,
                value: 'name',
            },
            { text: 'Company', value: 'company' },
            { text: 'City', value: 'city' },
            { text: 'State', value: 'state' },
        ]
    },
    materialUITable: [
        {
            id: 4972138710961563133,
            name: '测量大师',
            appid: 'com.sinognss.sm.free',
            users: 10000,
            price: '$25 224.2',
            date: '2020-12-25',            
            status: '暂停',
        },
       
        {
            id: 4972138710961563134,
            name: '导航大师',
            appid: 'com.sinognss.nm',
            users: 10000,
            price: '$25 224.2',
            date: '2020-12-31',            
            status: '正式版',
        },
        
    ],
    menu: [
        'Edit',
        'Copy',
        'Delete',
        'Print'
    ]
}
