<template>
  <v-app>
   
    <v-container fluid>
      <v-row no-gutters>
        <v-col cols="7" class="main-part d-none d-md-none d-lg-flex">
          <div class="d-flex">
            <v-img src="@/assets/logo.svg" contain></v-img>
            <p>AAPP</p>
          </div>
        </v-col>
        <v-col cols="12" lg="5" class="login-part d-flex align-center justify-center">
          <v-row no-gutters>
            <v-col cols="12" class="login-part d-flex align-center justify-center flex-column">
              <div class="login-wrapper">
                <!-- <v-tabs grow :value="activeTab">
                  <v-tabs-slider></v-tabs-slider>
                  <v-tab :href="`#tab-login`">
                    登录
                  </v-tab>
                  <v-tab :href="`#tab-newUser`">
                    注册
                  </v-tab>

                  <v-tab-item :value="'tab-login'" > -->
                    <v-form>
                      <v-container>
                        <v-row class="flex-column">
                          <v-col>
                            <p class="login-slogan display-2 text-center font-weight-medium my-10">您好, 用户</p>
                            
                          </v-col>
                          
                          <v-form>
                            <v-col>
                              <!-- <v-text-field
                                  v-model="email"
                                  :rules="emailRules"
                                  value="admin@flatlogic.com"
                                  label="Email Address"
                                  required
                              ></v-text-field> -->
                              <v-text-field
                                  v-model="username"
                                  label="用户名"
                                  :rules="userNameRules"
                                  required
                              ></v-text-field>
                              <v-text-field
                                  v-model="password"
                                  :rules="passRules"
                                  type="password"
                                  label="密码"
                                  hint="至少6个字符"
                                  required
                              ></v-text-field>

                            </v-col>
                            <!-- <v-col class="d-flex justify-space-between"> -->
                            <v-col class="d-flex justify-center">
                              <v-btn
                                  class="text-capitalize"
                                  large
                                  :disabled="password.length === 0 || email.length === 0"
                                  color="primary"
                                  @click="login"
                              >
                                登录</v-btn>
                              <!-- <v-btn large text class="text-capitalize primary--text">Forget Password</v-btn> -->
                            </v-col>
                          </v-form>
                        </v-row>
                      </v-container>
                    </v-form>
                  <!-- </v-tab-item>

                  <v-tab-item :value="'tab-newUser'" >
                    <v-form>
                      <v-container>
                        <v-row class="flex-column">

                          <v-col>
                            <p class="login-slogan display-2 text-center font-weight-medium mt-10">欢迎!</p>
                            <p class="login-slogan display-1 text-center font-weight-medium mb-10">创建新用户</p>
                          </v-col>

                          <v-form>
                            <v-col>
                              <v-text-field
                                  v-model="createUsername"
                                  label="用户名"
                                  :rules="userNameRules"
                                  required
                              ></v-text-field>
                              <v-text-field
                                  v-model="createEmail"
                                  :rules="emailRules"
                                  label="邮箱"
                                  required
                              ></v-text-field>
                              <v-text-field
                                  v-model="createPassword"
                                  :rules="passRules"
                                  type="password"
                                  label="密码"
                                  hint="至少6个字符"
                                  required
                              ></v-text-field>
                            </v-col>
                            <v-col class="d-flex justify-space-between">
                              <v-btn
                                  large
                                  block
                                  :disabled="createUsername.length === 0 || createEmail.length === 0 || createPassword === 0"
                                  color="primary"
                                  @click="register"
                              >
                                创建新用户</v-btn>
                            </v-col>
                          </v-form>

                          
                          
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-tab-item>

                </v-tabs> -->
              </div>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <v-footer>
                <div class="primary--text">© 2021 <a href="https://apps.sinognss.com/" class="text-decoration-none">AAPP</a> 保留所有权利。</div>
              </v-footer>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    
   
  </v-app>
 
</template>

<script>
import axios from 'axios';
  export default {
    name: 'Login',
    data() {
      return {
       
        activeTab:'tab-login',
        username:'',
        createUsername:'',
        email: 'admin@flatlogic.com',
        emailRules: [
          v => !!v || '邮箱必填',
          v => /.+@.+/.test(v) || '邮箱格式错误',
        ],
        userNameRules: [
          v => !!v || '用户名必填',
        
        ],
        createEmail: '',
        createPassword: '',
        password: '',
        passRules: [
          v => !!v || '密码必填',
          v => v.length >= 6 || '至少6个字符'
        ]
      }
    },
    mounted(){
      document.onkeydown = () =>{
            var key = window.event.keyCode;
            if (key == 13) {
              if(this.password.length === 0 || this.email.length === 0){
                return;
              }
              this.login();
                
            }
        }
    },
    methods: {
      login(){
        //window.localStorage.setItem('authenticated', true);
        // this.$router.push('/apps');
        this.$http.post('/auth',{username:this.username,password:this.password},{'Content-Type':'application/json'}).then(res=> {
                if(res.code==0){    
                  let resData=res.data;
                  axios.defaults.headers.Authorization='Bearer '+resData.token;
                   this.$router.push('/apps');
                   window.localStorage.setItem('authenticated', true);
                   window.localStorage.setItem('token', 'Bearer '+resData.token);
                   window.localStorage.setItem('userName', resData.userName);
                   window.localStorage.setItem('email', resData.email);
                   this.$store.commit('setAdmin', resData.isAdmin); 
                }
                else{
                    this.$toast.error('请求失败',{
                    position: "top-center",
                    timeout: 3000,
                    hideProgressBar: true,
                  });
                }
            }).catch((error)=>{
              console.log(error)
              this.$toast.error('账号或密码错误',{
                    position: "top-center",
                    timeout: 3000,
                    hideProgressBar: true,
                  });
             
            });
      },
      register(){
        this.$http.post('/auth/register',{username:this.createUsername,email:this.createEmail,password:this.createPassword},{'Content-Type':'application/json'}).then(res=> {
                if(res.code==0){
                  this.$toast.success('注册成功,请登录');
                
                  this.activeTab='tab-login';
                
                }
                else{
                  this.$toast.error('请求失败');
                }
            }).catch(function (error) {console.log(error);});
      }
    },
    created() {console.log(window.localStorage.getItem('token'))
      if (window.localStorage.getItem('authenticated') === 'true') {
        this.$router.push('/apps');
      }
    }
  }

</script>

<style src="./Login.scss" lang="scss"/>
