import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist'
Vue.use(Vuex);
const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})
export default new Vuex.Store({

  namespace: true,
  state: {
    drawer: true,
    appDetail:{
      appName:'',
      appIcon:'',
      aid:'',
      appid:''
    },
    appName:'',
    appIcon:'',
    aid:'',
    mobileMode: false,
    isAdmin:false,
    appStoreType:0,
  },
  mutations: {
    toggleDrawer(state) {      
      state.drawer = !state.drawer;
    },
    setAppName(state,data){
      state.appName=data
    },
    setAppIcon(state,data){
      state.appIcon=data
    },
    setAppDetail(state,data){
      state.appDetail.appIcon=data.appIcon;
      state.appDetail.appName=data.appName;
      state.appDetail.aid=data.aid;
      state.appDetail.appid=data.appid;
    },
    setAppStoreType(state,data){
      state.appStoreType=data
    },
    setMobileMode(state, mode) {
      state.mobileMode = mode;
    },
    setDrawer(state, data) {
      state.drawer = data;
    },
    setAdmin(state, data) {
      state.isAdmin = data;
    },
  },
  actions: {
    TOGGLE_DRAWER({ commit }) {
      commit('toggleDrawer');
    }
  },
  getters: {
    DRAWER_STATE(state) {
      return state.drawer;
    }
  },
  plugins: [vuexLocal.plugin]
});
