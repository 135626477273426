<template>
  <v-navigation-drawer
    app
    clipped
    floating
    v-model="DRAWER_STATE"
    :mini-variant="!DRAWER_STATE"
    :width="sidebarWidth"
    :permanent="$vuetify.breakpoint.mdAndUp"
    :temporary="$vuetify.breakpoint.smAndDown"
    :mini-variant-width="sidebarMinWidth"    
    :class="{'drawer-mini': !DRAWER_STATE}">
    <v-list shaped >
      <template v-for="(item, i) in items">
        <v-row
          v-if="item.heading"
          :key="item.heading"
          align="center">
          <v-col cols="6">
            <span
              style="padding-left: 32px"
              class="text-body-1 subheader"
              :class="(item.heading && DRAWER_STATE) ? 'show ' : 'hide'">
              {{ item.heading }}
              </span>
          </v-col>
          <v-col
            cols="6"
            class="text-center">
          </v-col>
        </v-row>
        <v-divider
          v-else-if="item.divider"
          :key="i"
          dark
          class="my-3"
        ></v-divider>
        <v-list-group
          color="primary"
          v-else-if="item.children && DRAWER_STATE"
          :key="item.title"
          v-model="item.model"
          active-class="activeGroup"
          append-icon="">
            <template v-slot:prependIcon>
              <v-icon size="28">{{ item.icon }}</v-icon>
            </template>
            <template v-slot:activator >
              <v-list-item-content >
                <v-list-item-title
                  class="text-grey">
                    {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="(child, i) in item.children"
              :key="i"
              v-bind:to="{name:child.link}"
             
              link>
                <v-list-item-action v-if="child.icon">
                  <v-icon size="">{{ child.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="text-grey">
                    {{ child.title }}
                  </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list-group>
        <v-btn
          v-else-if="item.back"          
          :key="item.text"
          :to="item.link === '#' ? null : {name:item.link}"          
          class="mt-2 mb-4 mx-3 pr-16 text-grey"          
          color="text-grey"
          text
          outlined>           
          <v-list-item-action>
            <v-icon
              size="28"
              :color="item.color ? item.color : ''"
            >{{ item.icon }}</v-icon>
          </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title   
                class="text-grey"             
                link>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
           
        </v-btn>
        <v-list-item        
          color="primary"
          v-else
          :key="item.text"
          :to="item.link === '#' ? null : {name:item.link}"
          link>
          <v-list-item-action>
            <v-icon
              size="28"
              :color="item.color ? item.color : ''"
            >{{ item.icon }}</v-icon>
          </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title
                class="text-grey"
                link>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        
      </template>
    </v-list>
    </v-navigation-drawer>
</template>

<script>
import {mapActions, mapState} from 'vuex'

  export default {
    props: {
        source: String,
        items: Array
    },
    data(){
      return {
        /*items: [
          { title: 'Dashboard', icon: 'mdi-home', link: '/dashboard' },
          { title: '所有应用', icon: 'mdi-apps', link: '/apps' },
          { title: 'Typography', icon: 'mdi-format-size', link: '/typography' },
          { title: 'Tables', icon: 'mdi-grid-large', link: '/tables' },
          { title: 'Notifications', icon: 'mdi-bell-outline', link: '/notifications' },
          {
            title: 'UI Elements',
            icon: 'mdi-image-filter-none',
            link: '/icons',
            model: false,
            children: [
              { title: 'Icons', icon: 'mdi-circle-small', link: '/icons'},
              { title: 'Charts', icon: 'mdi-circle-small', link: '/charts'},
              { title: 'Maps', icon: 'mdi-circle-small', link: '/maps'},
            ],
          },
          { divider: true },
          { heading: 'HELP' },
          { title: 'Library', icon: 'mdi-book-variant-multiple'},
          { title: 'Support', icon: 'mdi-forum'},
          { title: 'FAQ', icon: 'mdi-help-circle-outline'},
          { divider: true },
          { heading: 'PROJECTS' },
          { title: 'My recent', icon: 'mdi-circle-medium', color: 'warning'},
          { title: 'Starred', icon: 'mdi-circle-medium', color: 'primary'},
          { title: 'Background', icon: 'mdi-circle-medium', color: 'error'}

        ],*/
        sidebarWidth: 240,
        sidebarMinWidth: 76,
        screenWidth: document.body.clientWidth,
      }
    },
    computed: {
      ...mapState(['drawer','mobileMode']),
      DRAWER_STATE: {
        get() {
          return this.drawer
        },
        set(newValue) {
          this.$store.commit('setDrawer', newValue); 
          // if (newValue === this.drawer) return;
          // this.TOGGLE_DRAWER();
        }
      }
    },
    watch: {
            screenWidth() {
                if (this.screenWidth < 768) {
                    this.$store.commit('setDrawer', false);                  
                    this.$store.commit('setMobileMode', true);
                   
                }else{
                  this.$store.commit('setDrawer', true);
                    this.$store.commit('setMobileMode', false);
                }
            },
           
    },
    mounted(){
      window.onresize = () => {
                return (() => {
                    this.screenWidth = document.body.clientWidth;
                })()
            }
            if (this.screenWidth < 768) {
              this.$store.commit('setDrawer', false);
                this.$store.commit('setMobileMode', true);
               
            }
    },
    methods: {
      ...mapActions([ 'TOGGLE_DRAWER' ]),
    }
  }
</script>

<style src="./Sidebar.scss" lang="scss"/>
