<template>
  <v-container fluid>
    <div class="tables-basic">
      <v-row no-gutters class="d-flex justify-space-between mt-10 mb-6">
        <h1 class="page-title ">信息中心</h1>
      </v-row>
      <v-row>        
          <v-col cols="12">
            <v-card>
              <v-row class="flex-row pa-4">

          <v-list-item two-line >
            <v-list-item-action class="mr-4">
              <v-img :src="appDetail.iconUrl" max-height="48" max-width="48"></v-img>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title
                class="text-h6"
                >
                {{ appDetail.zhName }}
              </v-list-item-title>
              <v-list-item-subtitle v-if="appDetail.state==0"> 
                {{ appDetail.appid }} 

                <a class="ml-3 text-decoration-none" target="_blank" @click="toAppStore">前往App主页查看</a>
              </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
                
               
              </v-row>
              
          </v-card>
         </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>



export default {  
  name: 'Production',
  data() {
    return {
     
      appDetail:{}
    }
  },
  computed:{
    appId(){return this.$route.params.id},
    //appDetail(){return this.$store.state.currentApp},
  },
  mounted(){
    this.getAppDetail();
  },
  methods:{
    getAppDetail(){
      this.$http.get('/api/app/'+this.appId).then(res=> {
                if(res.code==0){
                   this.appDetail=res.data;
                }
                else{
                  this.$toast.error('请求失败');
                   
                }
            }).catch(function (error) {console.log(error);});
    },
    toAppStore(){
      // this.$store.commit("setAppDetail",{ 
      //   appName:this.appDetail.zhName,
      //   appIcon:this.appDetail.iconUrl,
      //   aid:this.appDetail.id,
      //   appid:this.appDetail.appid,
       
      // });
      this.$store.commit("setAppStoreType",0);
      window.open((this.$router.resolve('/store/apps/details?appid='+this.appDetail.appid)).href,'_blank')
    }
  }
}

</script>

<style src="./Basic.scss" lang="scss"></style>
