<template>
    <div class="headerContainer" :class="{blue:appStoreType==1}">
        <div class="innerContainer">
            <div class="left">
                <img :src="appIcon" width="30" height="30" v-if="appIcon"/>
                <strong>{{title}}</strong>
            </div>
            <div class="right">
                <v-btn text color="#fff" @click="toMainPage(0)">
                    首页
                </v-btn>
                <v-btn text color="#fff" @click="toMainPage(1)">
                    更新
                </v-btn>
                <!-- <v-btn text color="#fff" :to="`/store/apps/log?aid=${aid}`">
                    更新
                </v-btn> -->
                <span class="android">
                    <v-icon  
                   
                    >fa-android</v-icon>
                    Android
                </span>
            </div>
        </div>
       
    </div>
</template>

<script>
import './index.scss'
export default {
    props:{
        title:{
            type:String,
            default:''
        },
        appIcon:{
            type:String,
            default:''
        },
        appid:{
            type:String,
            default:''
        },
        // aid:{
        //     type:Number,
        //     default:0
        // }
    },
    computed:{
       
        appStoreType(){return this.$store.state.appStoreType},
    },
    mounted(){
        
    },
    data() {
        return {

        }
    },
    methods:{
        toMainPage(type){           
            this.$emit("typechange",type)          
        }
    }
}
</script>