<template>
  <v-container fluid>
    <div class="tables-basic">
      <v-row no-gutters class="d-flex justify-space-between mt-10 mb-6">
        <h1 class="page-title">{{appId?'修改应用':'创建应用'}}</h1>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card class="pa-4">
            <v-row>
              <v-col cols="2">
                <div class="mt-3 body-2" for="zhName">应用名称（中文）</div>
              </v-col>
              <v-col lg="5" sm="10">
                <v-text-field
                  id="zhName"
                  outlined
                  single-line
                  full-width
                  label=""  
                  :maxlength="50"    
                  counter="50"
                  v-model="zhName"
                ></v-text-field>
               
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2">
                <div class="mt-3 body-2" for="enName">应用名称（英文）</div>
              </v-col>
              <v-col lg="5" sm="10">
                <v-text-field
                  id="enName"
                  outlined
                  single-line
                  full-width
                  label="" 
                  :maxlength="50"     
                  counter="50"
                  v-model="enName"
                ></v-text-field>
                <p class="caption">
                  这是您的应用在
                  推广时显示的名称。应用名称应简练，不能包含价格、排名信息，以及任何表情符号或重复的符号。
                </p>
              </v-col>
            </v-row>
            <v-row v-if="appId">
              <v-col cols="2">
                <div class="mt-3 body-2" for="zhName">图标</div>
              </v-col>
              <v-col lg="5" sm="10">
                <div class="file-area pa-4 rounded">
                  <div>
                    <!-- <el-upload
                      class="upload-demo"
                      name="icon"
                      drag
                      action="/api/apps/icon/upload"
                      :data="uploadFileData"
                      :on-success="uploadSuccess"
                     
                      list-type="picture"
                      :show-file-list="false"
                      >
                      <img v-if="appImgUrl" :src="appImgUrl" class="appImg"/>
                      <i class="el-icon-upload" v-else></i>
                      <div class="el-upload__text">将PNG或JPEG文件拖到此处，或<em>点击上传</em></div>
                     
                    </el-upload> -->
                    <el-upload
                      action=""
                      class="upload-demo"
                      ref="appImg"
                      name="icon"
                      drag                    
                      :on-change="fileChange"                     
                      :auto-upload="false"
                      list-type="picture"
                      :show-file-list="false"
                      :before-upload="beforeUpload"
                      >
                      <img v-if="appImgUrl" :src="appImgUrl" class="appImg"/>
                      <i class="el-icon-upload" v-else></i>
                      <div class="el-upload__text">将PNG或JPEG文件拖到此处，或<em>点击上传</em></div>
                     
                    </el-upload>
                  </div>
                </div>
               
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-app-bar fixed class="pr-5" height="68" color="white" bottom>
      <v-spacer></v-spacer>
     
        <v-btn class="mr-2" text @click="deleteChange" v-if="appId"> 舍弃更改</v-btn>
        <v-btn class="mr-2" text to="/apps" v-else> 取消</v-btn>
       
        <v-btn  
          v-if="appDetail.state==0||appDetail.state==1"      
          @click="updateAppState(9)"
          text
          class="mr-2"                    
        >      
        禁用
      </v-btn>
      <v-btn 
        v-if="appDetail.state==9"        
        color="primary"
        @click="updateAppState(0)"
        class="mr-2"
        >
        启用
      </v-btn>
        <v-btn 
          elevation="0" 
          :disabled="createDisabled"
          color="primary"
          @click="saveApp"
          >
          {{appId?'保存':'创建应用'}}</v-btn>
          
    </v-app-bar>
  </v-container>
</template>
<style>
  .upload-demo{
    text-align: center;
  }
  .appImg{
    width:150px;
    height:150px;
  }
</style>
<script>
export default {
  name: "CreateNewApp",
  data() {
    return {
      zhName:'',
      enName: "",
      createDisabled: true,
     
      appImgUrl:'',
      appImgFile:{},
      appDetail:{}
    };
  },
  watch: {
    zhName: {
      handler(newValue) {
        this.createDisabled = newValue.length == 0||this.enName.length == 0;
      },
    },
    enName: {
      handler(newValue) {
        this.createDisabled = newValue.length == 0||this.zhName.length == 0;
      },
    },
  },
  mounted(){
    
    if(this.appId){
      this.getAppDetail();
     
    }
  },
  computed: {
    appId(){return this.$route.params.id},
    // appDetail(){return this.$store.state.currentApp},
    uploadFileData(){return {aid:this.appId}}
  },
  methods: {
    getAppDetail(){
      this.$http.get('/api/app/'+this.appId).then(res=> {
                if(res.code==0){
                   this.appDetail=res.data;
                   this.zhName=this.appDetail.zhName;
                  this.enName=this.appDetail.enName;
                  this.appImgUrl=this.appDetail.iconUrl;
                }
                else{
                  this.$toast.error('请求失败');
                   
                }
            }).catch(function (error) {console.log(error);});
    },
    updateAppState(state){
    
      let keyword=state==9?'禁用':'启用';
      this.$http.post('/api/apps/state/update',{aid:this.appId,state:state}).then(res=> {
                if(res.code==0){
                  this.$toast.success(`${keyword}应用成功`);
                  this.$router.push('/apps')
                }
                else{
                  this.$toast.error(res.message);
                }
            }).catch(function (error) {console.log(error);});
    },
    deleteChange(){
      this.zhName=this.appDetail.zhName;
      this.enName=this.appDetail.enName;
    },
    uploadSuccess(response, file){
     
      if(response.code==0){
        this.$toast.success(`上传成功`);
        this.appImgUrl=file.url;
      }else{
        this.$toast.error(response.message);
        this.appImgUrl='';
      }
    },
    fileChange(file){
      // console.log(file);
      this.appImgUrl = file.url;
    },
    beforeUpload(file){
        this.appImgFile = file;           
        return false;
    },
    saveApp(){
      this.appId?this.updateApp():this.createApp();
    },
    createApp(){ 
      let postData={
        zhName:this.zhName,
        enName:this.enName
      };
     
      this.$http.post('/api/apps/create',postData).then(res=> {
                if(res.code==0){
                  this.$toast.success(`创建应用成功`);
                  this.$router.push('/apps')
                }
                else{
                  this.$toast.error(res.message);
                }
            }).catch(function (error) {console.log(error);});
     
    },
    updateApp(){
      let formData = new FormData();
      this.$refs.appImg.submit();
      formData.append("aid", this.appId);
      formData.append("zhName", this.zhName);
      formData.append("enName", this.enName);
      if (Object.keys(this.appImgFile).length != 0) {
       formData.append("icon", this.appImgFile);
      }
      this.$http.post("/api/apps/update",formData,  { "Content-Type": "multipart/form-data" }
      ).then(res=>{
                                   
                        if(res.code==0){
                          this.$toast.success(`修改应用成功`);
                            
                        }else{
                          this.$toast.error(`修改应用失败`);
                        }
                    })
    }
  },
};
</script>


