<template>
    <div style="height:100%">
       
        <router-view></router-view>
    </div>
</template>

<script>
 
export default {
    
    data() {
        return {

        }
    },
    mounted(){
      
    },
    computed:{
      
    },
    
}
</script>