<template>
    <div class="appLogContainer">
        
        <div class="contentContainer">
            <h1>版本更新日志</h1>
            <v-divider></v-divider>
            <!-- <div v-for="(item,index) in updateLogList" :key="index">
                <h2>{{item.versionName}}</h2>
                <mavon-editor 
                    class="previewEditor"                    
                    v-model="item.versionLog"
                    :subfield="markdown.subfield"  
                    :defaultOpen="markdown.defaultOpen" 
                    :toolbarsFlag="markdown.toolbarsFlag" 
                    :editable="markdown.editable"
                />
            </div> -->
            <mavon-editor 
                class="previewEditor"                    
                v-model="updateLogTotal"
                :subfield="markdown.subfield"  
                :defaultOpen="markdown.defaultOpen" 
                :toolbarsFlag="markdown.toolbarsFlag" 
                :editable="markdown.editable"
            />
        </div>
       
       
    </div>
</template>

<script>
import './index.scss'
// import StoreHeader from "../../../components/StoreHeader/index";
export default {
   
    data() {
        return {
            appStore:{
                appZhName:'',
                qrCodeUrl:'',
                uversion:''
            },
            markdown:{
                subfield:false,
                defaultOpen: 'preview',
                editable: false,
                toolbarsFlag: false,
            },
            language:'zh-cn',
            updateLogList:[],
            updateLogTotal:''
        }
    },
    // components: {  
    //     StoreHeader
    // },
    computed:{
        // aid(){return this.$store.state.appDetail.aid}
        appid(){return this.$route.query.appid},
    },
    mounted(){
        this.language=(navigator.language || navigator.browserLanguage||navigator.userLanguage).toLowerCase();
        
        this.getAppLog();
    },
    methods:{
        getAppLog(){
            this.$http.get('/api/app/logs?appid='+this.appid).then(res=> {
                if(res.code==0){
                    this.updateLogList=res.data;
                    this.updateLogList.forEach(i=>{
                        i.versionLog=this.language.includes('zh')?i.versionLogObj['zh-cn']:i.versionLogObj['en-us'];
                        this.updateLogTotal+=`\n## ${i.versionName}\n${i.versionLog}`
                    })
                }
                else{
                  this.$toast.error(`请求失败`);
                }
            }).catch(function (error) {console.log(error);});
        },
       
    }
}
</script>