import axios from 'axios';
import qs  from 'qs';
import router from '../Routes'
axios.defaults.timeout = 10000;
axios.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
if(window.localStorage.getItem('token')){
  axios.defaults.headers.Authorization=window.localStorage.getItem('token');
}

//请求参数序列化
axios.interceptors.request.use(function (config) {
//宣传页和日志接口，登录接口不需要token
  if(config.url=="/api/apps/details"||config.url.includes("/api/app/logs") || config.url.includes("/auth")){
    config.headers.Authorization='';
  }
  if(config.data&&config.headers['Content-Type'] !== 'application/json'&&config.headers['Content-Type'] !== 'multipart/form-data'){
    config.data = qs.stringify(config.data,{arrayFormat: 'repeat'});
  }
	return config;
}, function (error) {
	return Promise.reject(error);
});

axios.interceptors.response.use((res) =>{
 
  if(!res.data.success){
    return Promise.resolve(res);
  }
  return res;
}, (error) => {
  let response=error.response;
  if(response.config.url!='/auth'&&response.status==403){//不是登录接口，并且返回状态码是403，则跳转到登录
    window.localStorage.setItem('authenticated', false);
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('userName');
    window.localStorage.removeItem('email');
    router.push('/login');
  }
  return Promise.reject(error);
});
//get请求

function get(url, params){    
  return new Promise((resolve, reject) =>{        
      axios.get(url, {            
        params: params        
    }).then(res => {
          resolve(res.data);
      }).catch(err =>{
          reject(err)        
      })    
  })
}
//post请求
function post(url, params, headers) {
  return new Promise((resolve, reject) => {
    axios.post(url,  params,{headers:{...axios.defaults.headers,...headers}})
      .then(res => {console.log('请求成功')
        resolve(res.data);
      }, err => {console.log('请求失败',err)
        reject(err);
      })
      .catch((error) => {console.log('请求失败catch',error)
        reject(error)
      })
  })
}


export default{
    get,post,
}