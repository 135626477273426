<template>
  <version title='正式版' :type="0"></version>
</template>

<script>


import version from "../../../components/version/index";
export default {
  name: "Production",
  components: {  
    version
  },
  data() {
    return {

    };
  },
  
};
</script>

