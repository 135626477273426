<script>
import VueUploadComponent from "vue-upload-component";
export default {
  extends: VueUploadComponent,
  name: "VFileUpload",
  components: {},
  data() {
    return {
      
    };
  },
  computed: {},
  methods: {    
  },
  watch:{
     dropActive:{
        handler(newValue, oldValue){
          if(newValue != oldValue){
            this.$emit('drop-changed', newValue);
          }                    
        }
     },
     active:{
        handler(newValue, oldValue){
          if(newValue != oldValue){
            this.$emit('active-changed', newValue);
          }                    
        }
     }
  }
};
</script>